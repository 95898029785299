const partnersLinks = {
  "4glaza": "https://www.4glaza.ru/shops/ekaterinburg/",
  administration: "https://екатеринбург.рф",
  bibliotechniycenter: "https://events.bgekb.ru",
  bibliotekabelinskogo: "http://book.uraic.ru",
  centeritcube: "http://айтикуб.рф",
  cosmoport: "https://vk.com/cosmoport.club",
  ekbclub: "https://eklat.jimdofree.com",
  englishdrive: "https://englishdrive.ru",
  hochuvnauku: "https://vnauku.ru/",
  homecomputer: "https://ит-школа.рф/index.html",
  icae: "https://myatom.ru",
  igronaphtic: "https://igronaftik.ru",
  kantrscrip: "https://kantrskrip.ru",
  l52: "https://m-i-e.ru/l52",
  locman: "https://lotsman.ru",
  molodeznayapolicy: "http://молодежь.екатеринбург.рф",
  muzeivdv: "https://ugvim.ru",
  npo: "https://www.npoa.ru",
  odarennost: "http://gifted.ru",
  planetariy:
    "https://mkugmk.ru/ru/about-complex/exhibition-centers/planetariy/",
  schoolbus: "https://s-coolbus.ru",
  smartschool: "https://smartschoolekb.ru",
  soundcity: "https://sndc.ru",
  targemgames: "https://targem.ru",
  zolotoesechenie: "https://zsfond.ru",
  fiit: "https://fiit-urfu.ru/",
  babyklub:
    "https://baby-club.ru/clubs/ekaterinburg/academic/?utm_source=yandex&utm_medium=map&utm_campaign=ad_mart",
  pripev: "https://vk.com/pripev_akadem",
};

export default partnersLinks;
