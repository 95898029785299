const info = [
    {
        'count': '3500+',
        'item': 'Участников'
    },
    {
        'count': '8',
        'item': 'Дней'

    },
    {
        'count': '>100',
        'item': 'Мероприятий'
    },
    {
        'count': '21',
        'item': 'Площадка'
    }
]

export default info;